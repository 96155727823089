import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../components/Layout';
// import NavOne from '../../components/NavOne';
// import PageHeader from '../../components/PageHeader';
// import Footer from '../../components/Footer';
import Tabs from '../components/Tabs';
import sanu from '../assets/images/departments/sanu.png';
import oredugba from '../assets/images/departments/oredugba.png';
import dacosta from '../assets/images/departments/dacosta.png';
import utomi from '../assets/images/departments/utomi.png';
import orenuga from '../assets/images/departments/orenuga.png';
import umeh from '../assets/images/departments/umeh.png';
import isiekwe from '../assets/images/departments/isiekwe.png';
import olatosi from '../assets/images/departments/olatosi.png';

const HealthResearch = () => {
  // const resident = [
  //   {
  //     name: 'Dr. Adesuwa Abe',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Benin)',
  //     mdcn: '38,951',
  //   },
  //   {
  //     name: 'Dr. B.O. Akindele',
  //     designation: 'Snr. Registrar',
  //     qualification: 'B.ch.D (Ife)',
  //     mdcn: '71,371',
  //   },
  //   {
  //     name: 'Dr. O.A Osibogun',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Ibadan)',
  //     mdcn: '66,062',
  //   },
  //   {
  //     name: 'Dr A.R Majekodunmi',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '64,918',
  //   },
  //   {
  //     name: 'Dr. T.S Jauro',
  //     designation: 'Registrar',
  //     qualification: 'BDS ',
  //     mdcn: '80,333',
  //   },
  //   {
  //     name: 'Dr O.T Odunlami',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '85,676',
  //   },
  //   {
  //     name: 'Dr C.T Adeniji',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '64,912',
  //   },
  //   {
  //     name: 'Dr T. Alakwem',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Benin)',
  //     mdcn: '73,367',
  //   },
  // ];

  // const orthodontics = [
  //   {
  //     name: 'Dr. S.E. Chegwe',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Benin)',
  //     mdcn: '1401',
  //   },
  //   {
  //     name: 'Dr. M.O. Akeredolu',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '51,848',
  //   },
  //   {
  //     name: 'Dr. A. Ben-Okoye',
  //     designation: 'Snr. Registrar',
  //     qualification: 'B.CDH (Ife)',
  //     mdcn: '63,801',
  //   },
  //   {
  //     name: 'Dr. O. Ayenakin',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Benin)',
  //     mdcn: '62,663',
  //   },
  //   {
  //     name: 'Dr A. Koledoye',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Ibadan)',
  //     mdcn: '60,998',
  //   },
  //   {
  //     name: 'Dr O.T Odunlami',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '85,676',
  //   },
  //   {
  //     name: 'Dr A.Y Adeloye',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '35,643',
  //   },
  //   {
  //     name: 'Dr. Odah',
  //     designation: 'Snr. Registrar',
  //     qualification: 'BDS (Maiduguri)',
  //     mdcn: '67,286',
  //   },
  //   {
  //     name: 'Dr O.U Obidi',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Benin)',
  //     mdcn: '65,626',
  //   },
  //   {
  //     name: 'Dr. O.A Temiye',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '74,307',
  //   },
  //   {
  //     name: 'Dr A.S Eniola',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '74,310',
  //   },
  //   {
  //     name: 'Dr H.A Nnodebe',
  //     designation: 'Registrar',
  //     qualification: 'BDS',
  //     mdcn: '59,427',
  //   },
  //   {
  //     name: 'Dr N.A Ajie',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '81,527',
  //   },
  //   {
  //     name: 'Dr N.M Awwal',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Maiduguri)',
  //     mdcn: '65,832',
  //   },
  //   {
  //     name: 'Dr O Olu-Awe',
  //     designation: 'Registrar',
  //     qualification: 'BDS (Lagos)',
  //     mdcn: '89,943',
  //   },
  //   {
  //     name: 'Dr T.O Ogunye',
  //     designation: 'Registrar',
  //     qualification: 'BChD (Ife)',
  //     mdcn: '86,659',
  //   },
  // ];

  // const support = [
  //   {
  //     name: 'Miss M.A. Saliu',
  //     designation: 'Dental Therapist',
  //     qualification: 'HND, (Ilesa, Ogun)',
  //   },
  //   {
  //     name: 'Mr. Anothony E. Eze',
  //     designation: 'Dental Technologist',
  //     qualification: 'HND, (Enugu)',
  //   },
  //   {
  //     name: 'Mrs. J.O. Aponmade',
  //     designation: 'Chief. Confidential Secretary',
  //     qualification: 'Diploma in Secretarial Studies',
  //   },
  //   {
  //     name: 'Mrs. Taiwo Ige',
  //     designation: 'Chief Clerical Officer',
  //     qualification: '',
  //   },
  // ];

  // const residentRows = (type) => {
  //   return type.map((element) => (
  //     <tr key={element.name}>
  //       <td>{element.name}</td>
  //       <td>{element.designation}</td>
  //       <td>{element.qualification}</td>
  //       <td>{element.mdcn}</td>
  //     </tr>
  //   ));
  // };

  // const supportRows = support.map((element) => (
  //   <tr key={element.name}>
  //     <td>{element.name}</td>
  //     <td>{element.designation}</td>
  //     <td>{element.qualification}</td>
  //   </tr>
  // ));
  return (
    <Layout pageTitle='HEALTH RESEARCH ETHICS COMMITTEE | LUTH'>
      {/* <NavOne /> */}
      {/* <PageHeader title='Department of Child Dental Health' /> */}
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      HEALTH RESEARCH ETHICS COMMITTEE
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        <strong>Introduction: </strong>The LUTH HREC Committee
                        is accredited by the National Health Research Ethics
                        Committee (NHREC). It is charged with the responsibility
                        of ensuring that all research and clinical trials
                        carried out in the Institution are done in the right
                        ethical standard and good clinical practices as stated
                        by the National code for Health Research Ethics.
                      </p>
                      <br />
                      <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                        Composition of the LUTH HREC members:
                      </h2>
                      <p className='course-details__tab-text'>
                        The LUTH HREC Committee compose of Scientific and
                        non-scientific members, who are selected from the lay
                        public for a robust representation of the Nigerian
                        population.
                      </p>
                      <br />
                      <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                        Basic requirement for protocol submissions to the LUTH
                        HREC
                      </h2>
                      <p className='course-details__tab-text'>
                        Every researcher is expected to submit two copies of
                        their protocol in spiral bind form including the title
                        page, aims, methodology, informed consent document,
                        questionnaire, citiprogram online certificate and an
                        application letter addressed to Chairman LUTH HREC. For
                        non LUTH researchers or students will be required to
                        have an institutional collaborator or an institutional
                        co-supervisor in LUTH.
                      </p>
                      <br />
                      <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                        Duration for processing of submitted protocol
                      </h2>

                      <p className='course-details__tab-text'>
                        1. Protocols for Expedited review/approval (4 – 6 weeks){' '}
                        <br />
                        2. Protocols for Exempted approval (1 – 2 weeks) <br />3
                        Clinical trials protocols for Full Committee review (4 –
                        6 weeks depending)
                      </p>
                    </Spoiler>
                  </div>
                  <div label='New Applications'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      STANDARD OPERATING PROCEDURE (SOP) <br />
                      SUBMISSION AND PROCESSING OF NEW APPLICATIONS TO LUTH HREC{' '}
                      <br />
                      PURPOSES TO DESCRIBE THE PROCESS FOR SUBMISSION AND
                      PROCESSING OF NEW APPLICATIONS
                    </h2>
                    <p className='course-details__tab-text'>
                      1. Applications for review by the LUTH HREC can be
                      submitted on any week day from 8.00am to 3.00pm to the
                      LUTH HREC office, Room 107, Administration Building, LUTH,
                      Ishaga Road, Off Itire Road, Idi-Araba, Lagos State,
                      Nigeria. <br />
                      <br />
                      2. Applications will comprise of the following: <br />
                      a. Cover letter (addressed to the Chairperson, LUTH HREC).
                      The cover letter for applicants who are not permanent
                      staff (e.g. residents, undergraduate students,
                      postgraduate students) must be directed through the
                      relevant Head of Department (or Head of unit), and must be
                      countersigned by a competent supervisor who is a permanent
                      staff of the LUTH.
                      <br /> b. Brief (1 – 3 page; double spaced; font size
                      minimum 12) summary of the research protocol (in lay
                      terminology). <br />
                      c. Research protocol (2 hard copies, spiral bound and one
                      soft copy in pdf format on a compact disc) including the
                      informed consent document compliant with the recommended
                      format. <br />
                      <br />
                      3. For research protocols emanating from researchers whose
                      departments or examining bodies specify a format for
                      research protocols (e.g. the West African Postgraduate
                      Medical College, National Postgraduate Medical College,
                      etc), the LUTH HREC accepts protocol formatted according
                      to the specifications of the relevant body, with the
                      proviso that the format contains all the relevant aspects
                      and addresses all ethical issues as required by LUTH HREC.{' '}
                      <br />
                      <br />
                      4. Researchers from institutions outside LUTH who desire
                      to conduct their research within LUTH may do so only if
                      they are in collaboration with a competent and relevant
                      researcher on the permanent staff of LUTH. In such
                      instances, the corresponding investigator will be the LUTH
                      researcher, and will have the responsibility of ensuring
                      that the conduct of the research is ethical and is as
                      outlined in the approved protocol. <br />
                      <br />
                      5. Guidance in the preparation of the informed consent
                      document is available publicly to all researchers in the
                      form of a prototype document adapted from the NHREC
                      prototype, and can be obtained from the LUTH HREC office.{' '}
                      <br />
                      <br />
                      6. The secretariat will check all applications for
                      completeness prior to accepting the application.
                      Incomplete application will not be accepted by the
                      secretariat. <br /> <br />
                      7. Complete applications will be assigned a unique
                      identification. In all correspondence, reference should be
                      made to this unique ID. The unique ID will bear the prefix
                      ADM/DCST/HREC/#### (hash represents the unique number).{' '}
                      <br /> <br />
                      8. Acknowledgement of receipt will be made by issuance of
                      an acknowledgement or by email notification from the
                      secretariat. Documentation of receipt of application will
                      be made in the HREC records. <br /> <br />
                      9. All applications received will be evaluated by the
                      Chair and Secretary to determine the type of review to be
                      assigned (exempt review, expedited review or full review).
                      This determination will be made within 3 working days of
                      acceptance of a complete application. The determination of
                      review type will be made in accordance with recommendation
                      made by NHREC in the NCHRE
                      <br />
                      <br />
                      10. The precise status of a protocol regarding assignment
                      of review will be made by the Chair using guidelines
                      specified by the NHREC. Researchers cannot independently
                      determine that their study is exempt from ethical review.
                      <br />
                      <br />
                      11. In the case of exempt protocols, a letter of exemption
                      will be issued expeditiously by the Chair, and the
                      decision will be communicated to the committee at the next
                      scheduled meeting for ratification. <br /> <br />
                      12. In the case of expedited reviews, the LUTH HREC adopts
                      a method of assigning such reviews to expert reviewers
                      from the research community (members or non-members of the
                      LUTH HREC) comments from the reviewers are subsequently
                      received by the Chair and communicated anonymously to the
                      researchers in the event that the reviewers identify
                      ethical issues requiring clarification. Further
                      clarification may necessitate the researcher being invited
                      to attend to attend an HREC meeting in person to address
                      any pending issues. In the event that there are no ethical
                      issues, a provisional approval is issued to the researcher
                      (signed by the Chair). All decisions are reviewed at the
                      next scheduled meeting of the HREC and either ratified or
                      subjected to modification. Subsequently, a final decision
                      is provided to the researcher indicating the date of final
                      decision, duration of approval, and other relevant
                      information as recommended by the NHREC. <br />
                      <br />
                      13. For application requiring full review, the researcher
                      will be required to attend the HREC meeting. The
                      application will then be reviewed by members of HREC
                      (along with additional experts where the circumstance
                      arises) present at the meeting. The decision emanating
                      from such a review will be communicated to the researcher
                      in writing, including, where relevant, a time frame for
                      addressing any pending issues. <br />
                      <br />
                      14. In reviewing applications, due consideration will be
                      given to the science of the research as it impacts on
                      ethics, in addition to other conventionally assessed
                      ethical issues/All reviewers will be provided with a
                      guidance document to use as a checklist to ensure that
                      relevant ethical issues are reviewed in each protocol. The
                      guidance document is included in the appendix to this SOP,
                      is publicly available to researchers and reviewers, and
                      can be obtained from the LUTH HREC.
                      <br /> <br />
                      15. Request for modifications or clarifications will be
                      communicated to researchers in writing. Researchers will
                      be requested to address all such issues within 6 weeks. In
                      the event that no response is received within 8 weeks, the
                      protocol will be dismissed. The researcher may re-apply
                      thereafter.
                      <br /> <br />
                      16. In any case, review of protocols will be in accordance
                      with guidelines stipulated in the National Code of Health
                      Research Ethics (NCHRE) and the CIOMS/WHO guidelines, and
                      with due consideration of the host institution’s
                      operational guidelines. <br /> <br />
                      17. In arriving at a final decision regarding a protocol,
                      the decision will be based on unanimous decision of all
                      present at the meeting. In the event of dissent regarding
                      a decision by any members present, further guidance may be
                      sought by inviting an external expert and/or seeking
                      further clarification from the researchers. Alternatively,
                      two-thirds majority decision (including that of a relevant
                      expert and non-scientific lay member) will be deemed
                      sufficient. <br />
                      <br />
                      18. The LUTH HREC endeavors to complete all reviews within
                      8 weeks, and at the latest, within 12 weeks.
                    </p>
                    
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={sanu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Oyinkan Sofola.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Chairman LUTH Health Reasearch Ethics Committee (HREC)
                            <br />
                            {/* <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Ibadan) FWACS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics */}
                          </p>
                        </div>
                      </div>
                      {/* <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oredugba} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. F.A. Oredugba</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Benin), FWACS, MPH (Lagos), M SND RCSEd, FADPD,
                            FDSRCSED
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dacosta} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. O.O. daCosta</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={utomi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. I.L. Utomi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos) FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={orenuga} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.O. Orenuga</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, MPH (Lagos), CCRA (Canada)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olatosi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.O. Olatosi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div> */}
                      {/* <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={isiekwe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. G.I. Isiekwe</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, (Lagos), FMCDS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={umeh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.D. Umeh</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Benin) FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div> */}
                      {/* <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Paediatric Dentistry Resident Doctors
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>MDCN</th>
                          </tr>
                        </thead>
                        <tbody>{residentRows(resident)}</tbody>
                      </Table>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Orthodontics Resident Doctors
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>MDCN</th>
                          </tr>
                        </thead>
                        <tbody>{residentRows(orthodontics)}</tbody>
                      </Table>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Support Staff: Clinical / Administrative Services
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                          </tr>
                        </thead>
                        <tbody>{supportRows}</tbody>
                      </Table> */}
                    </React.Fragment>
                  </div>
                  {/* <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:childdentalhealth@luth.gov.ng'>
                          childdentalhealth@luth.gov.ng
                        </a>
                      </p>
                    </React.Fragment>
                  </div> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </Layout>
  );
};

export default HealthResearch;
