import React from 'react';
import Layout from '../../components/Layout';
// import blogd1 from '../../assets/images/2022/hrec/1.jpeg';
// import blogd2 from '../../assets/images/2022/federal-seminar/2.jpg';
// import blogd3 from '../../assets/images/2022/federal-seminar/3.jpg';
// import blogd4 from '../../assets/images/2022/federal-seminar/4.jpg';
// import blogd5 from '../../assets/images/2022/federal-seminar/5.jpg';
// import blogd6 from '../../assets/images/2022/federal-seminar/6.jpg';
// import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
// import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';
// import HealthResearch from '../../components/HealthResearch';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import HealthResearch from '../../components/HealthResearch';

// const NewsDetails = () => {
//   return (
//     <section className='blog-details'>
//       <div className='container'>
//         <div className='row'>
//           <div className='col-lg-12'>
//             <div className='blog-one__single'>
//               <div className='blog-one__image'>
//                 <img src={blogd1} alt='' />
//               </div>
//               <div className='blog-one__content text-center'>
//                 <h2 className='blog-one__title'>
//                   LAGOS UNIVERSITY TEACHING HOSPITAL HEALTH RESEARCH ETHICS
//                   COMMITTEE
//                 </h2>
//                 <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Introduction
//                 </span>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   The LUTH HREC Committee is accredited by the National Health
//                   Research Ethics Committee (NHREC). It is charged with the
//                   responsibility of ensuring that all research and clinical
//                   trials carried out in the Institution are done in the right
//                   ethical standard and good clinical practices as stated by the
//                   National code for Health Research Ethics.
//                 </p>
//                 {/* <div className='row'>
//                   <div className='col-lg-6'>
//                     <img src={blogd2} height={370} />
//                   </div>
//                   <div className='col-lg-6'>
//                     <img src={blogd3} height={370} />
//                   </div>
//                 </div> */}
//                 <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Composition of the LUTH HREC members:
//                 </span>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   The LUTH HREC Committee compose of Scientific and
//                   non-scientific members, who are selected from the lay public
//                   for a robust representation of the Nigerian population.
//                 </p>
//                 {/* <div className='row'>
//                   <div className='col-lg-12'>
//                     <img src={blogd4} height={370} />
//                   </div>
//                 </div> */}
//                 <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Basic requirement for protocol submissions to the LUTH HREC
//                 </span>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   Every researcher is expected to submit two copies of their
//                   protocol in spiral bind form including the title page, aims,
//                   methodology, informed consent document, questionnaire,
//                   citiprogram online certificate and an application letter
//                   addressed to Chairman LUTH HREC. For non LUTH researchers or
//                   students will be required to have an institutional
//                   collaborator or an institutional co-supervisor in LUTH.
//                 </p>
//                 {/* <div className='row'>
//                   <div className='col-lg-6'>
//                     <img src={blogd5} height={370} />
//                   </div>
//                   <div className='col-lg-6'>
//                     <img src={blogd6} height={370} />
//                   </div>
//                 </div> */}
//                 <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Duration for processing of submitted protocol
//                 </span>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   1. Protocols for Expedited review/approval (4 – 6 weeks)
//                   <br />
//                   2. Protocols for Exempted approval (1 – 2 weeks)
//                   <br />3 Clinical trials protocols for Full Committee review (4
//                   – 6 weeks depending)
//                 </p>
//                 {/* <div className='row'>
//                   <div className='col-lg-12'>
//                     <img src={blogd7} height={370} />
//                   </div>
//                 </div> */}
//                 <h4 style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   STANDARD OPERATING PROCEDURE (SOP) <br />
//                   SUBMISSION AND PROCESSING OF NEW APPLICATIONS TO LUTH HREC{' '}
//                   <br />
//                   PURPOSES TO DESCRIBE THE PROCESS FOR SUBMISSION AND PROCESSING
//                   OF NEW APPLICATIONS
//                 </h4>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   1. Applications for review by the LUTH HREC can be submitted
//                   on any week day from 8.00am to 3.00pm to the LUTH HREC office,
//                   Room 107, Administration Building, LUTH, Ishaga Road, Off
//                   Itire Road, Idi-Araba, Lagos State, Nigeria.
//                 </p>
//                 {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Management of DM
//                 </span> */}
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                 2. Applications will comprise of the following:
//                 </p>
//                 <span style={{ fontSize: 14, fontStyle: 'italic' }}>
//                   Prevention
//                 </span>
//                 <p className='blog-one__text' style={{ marginBottom: 30 }}>
//                   Maintain optimal body weight, physical exercise, (30 to 45mins
//                   of moderate exercise per day) healthy diet. Limit sugary and
//                   alcoholic beverages, Smoking cessation
//                 </p>
//               </div>
//             </div>
//             <div className='share-block'>
//               <div className='left-block'>
//                 <p>
//                   Tags: <a href='../../components/HealthResearch'>HEALTH RESEARCH ETHICS COMMITTEE</a>
//                 </p>
//               </div>
//               {/* <div className='social-block'>
//               <a href='#none'>
//                 <i className='fab fa-twitter'></i>
//               </a>
//               <a href='#none'>
//                 <i className='fab fa-facebook-f'></i>
//               </a>
//               <a href='#none'>
//                 <i className='fab fa-instagram'></i>
//               </a>
//               <a href='#none'>
//                 <i className='fab fa-dribbble'></i>
//               </a>
//             </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

const GalleryPage = () => {
  return (
    <Layout pageTitle='HEALTH RESEARCH ETHICS COMMITTEE '>
      <NavOne />
      <PageHeader title='' />
      {/* <NewsDetails /> */}
      <HealthResearch />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
